@charset "UTF-8";
/*******************/
/********************/
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
/*******************/
/********************/
/*******************/
/********************/
/*custom*/
/*******************/
/********************/
/*******************/
/********************/

:root {
  --bs-blue: #001c68;
  --bs-indigo: #5243c2;
  --bs-purple: #611f69;
  --bs-pink: #d63384;
  --bs-red: #dd3131;
  --bs-orange: #fd7e14;
  --bs-yellow: #fec572;
  --bs-green: #6b9f36;
  --bs-teal: #feefea;
  --bs-cyan: #fdf0ea;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #8a8a8a;
  --bs-gray-dark: #7a8496;
  --bs-gray-100: #f1f1f5;
  --bs-gray-200: #efefef;
  --bs-gray-300: #8c8c8c;
  --bs-gray-400: #7a8496;
  --bs-gray-500: #edecf9;
  --bs-gray-600: #8a8a8a;
  --bs-gray-700: #f2f2f8;
  --bs-gray-800: #7a8496;
  --bs-gray-900: #14141c;
  --bs-primary: #1642b9;
  --bs-secondary: #061434;
  --bs-info: #2776fc;
  --bs-light: #f9f9fb;
  --bs-warning: #ee7b11;
  --bs-primary-rgb: 22, 66, 185;
  --bs-secondary-rgb: 6, 20, 52;
  --bs-info-rgb: 39, 118, 252;
  --bs-light-rgb: 249, 249, 251;
  --bs-warning-rgb: 238, 123, 17;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 20, 20, 28;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: 'Manrope', sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1.125rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1;
  --bs-body-color: #14141c;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #8c8c8c;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 5px;
  --bs-border-radius-sm: 10px;
  --bs-border-radius-lg: 15px;
  --bs-border-radius-xl: 20px;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #1642b9;
  --bs-link-hover-color: #123594;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3e3;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.46875rem + 2.625vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 3.4375rem;
  }
}

h2,
.h2 {
  font-size: calc(1.45rem + 2.4vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 3.25rem;
  }
}

h3,
.h3 {
  font-size: calc(1.34375rem + 1.125vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 2.1875rem;
  }
}

h4,
.h4 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.75rem;
  }
}

h5,
.h5 {
  font-size: 1.125rem;
}

h6,
.h6 {
  font-size: 1.0625rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 900;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img,
svg {
  vertical-align: middle;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.265625rem + 0.1875vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.40625rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 5px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #8a8a8a;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #8a8a8a;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #8c8c8c;
  --bs-nav-tabs-border-radius: 5px;
  --bs-nav-tabs-link-hover-border-color: #efefef #efefef #8c8c8c;
  --bs-nav-tabs-link-active-color: #f2f2f8;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #8c8c8c #8c8c8c #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 5px;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #1642b9;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.359375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.40625rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.40625rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 15px;
  --bs-navbar-toggler-focus-width: 0px;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 55px;
  --bs-card-spacer-x: 60px;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: #2753c9;
  --bs-card-border-radius: 25px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 24px;
  --bs-card-cap-padding-y: 27.5px;
  --bs-card-cap-padding-x: 60px;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 5px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 5px;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(22, 66, 185, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(6, 20, 52, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(39, 118, 252, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(249, 249, 251, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(238, 123, 17, var(--bs-bg-opacity, 1)) !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.shadow {
  box-shadow: 15px 60px 190px rgba(86, 91, 106, 0.06) !important;
}

.shadow-sm {
  box-shadow: 0 10px 18px rgba(138, 138, 138, 0.1) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #8a8a8a !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.main-header {
  padding-top: 45px;
  transition: all 0.5s ease;
  z-index: 99;
}
.main-header .search {
  max-width: 140px;
}
.main-header .search .btn {
  line-height: 1;
}
.main-header .search .btn i {
  font-size: 20px;
}
.main-header .search .form-control {
  color: #fff;
}
.main-header .search .form-control::-moz-placeholder {
  color: #95a5ce;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
}
.main-header .search .form-control:-ms-input-placeholder {
  color: #95a5ce;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
}
.main-header .search .form-control::placeholder {
  color: #95a5ce;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
}
.main-header .navbar-collapse .navbar-nav {
  padding-left: 60px;
}
.main-header .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 17px;
  padding: 0px 50px 0 0;
  color: #061434;
  font-weight: 500;
}
.main-header .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
  color: #1642b9;
}
.main-header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  padding: 12px 0;
  background: #fff;
  box-shadow: 0px 10px 30px rgba(0, 71, 55, 0.1);
}
.main-header.sticky .search .form-control {
  color: #000;
}
.main-header.sticky .search .form-control::-moz-placeholder {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
}
.main-header.sticky .search .form-control:-ms-input-placeholder {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
}
.main-header.sticky .search .form-control::placeholder {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
}
.main-header.sticky .search i {
  color: #000 !important;
}
.main-header .nav-toggler i {
  font-size: 35px;
}
.main-header .offcanvas .offcanvas-header .btn-close {
  margin-top: -25px;
  margin-right: 0px;
}
.main-header .offcanvas .offcanvas-header .btn-close i {
  font-size: 35px;
  color: #061434;
}
.main-header .offcanvas .navbar-nav .nav-link {
  color: #061434;
  font-weight: 500;
  font-size: 20px;
}

.hero-banner {
  padding-bottom: 70px;
}
.hero-banner::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 65%;
  background-color: #f9f9fb;
  left: 0;
  top: 0;
}
.hero-banner::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-size: cover;
  background-image: url(./../../assets/images/hero/right-background.svg);
  background-repeat: no-repeat;
  z-index: -1;
}
.hero-banner .left-hero-color {
  padding-top: 75px;
  z-index: 1;
}
.hero-banner .left-hero-color::before {
  position: absolute;
  content: '';
  top: 150px;
  left: -150px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-image: url(./../../assets/images/hero/leftside-backlayer-icons.svg);
  background-repeat: no-repeat;
  z-index: -1;
}
.hero-banner .left-hero-color img {
  margin-left: -35px;
}
.hero-banner .left-hero-color h1,
.hero-banner .left-hero-color .h1 {
  font-size: 75px;
  max-width: 70%;
}
.hero-banner .left-hero-color p {
  color: #7a8496;
  font-size: 18px;
  padding: 30px 0;
  font-weight: 500;
}
.hero-banner .left-hero-color a span {
  transform: rotate(90deg);
}
.hero-banner .right-hero-color {
  padding-top: 250px;
  z-index: 1;
}
.hero-banner .right-hero-color::after {
  position: absolute;
  content: '';
  top: 250px;
  right: -150px;
  width: 35%;
  height: 70%;
  background-size: contain;
  background-image: url(./../../assets/images/hero/rightside-backlayer-icons.svg);
  background-repeat: no-repeat;
  z-index: -1;
}

.service {
  padding: 150px 0;
}
.service::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-image: url(./../../assets/images/service/service-backlayer.svg);
  background-repeat: no-repeat;
  z-index: -1;
  background-position: center;
}
.service .shape {
  top: -100px;
  right: -50px;
  height: 130px;
}
.service small,
.service .small {
  color: #7a8496;
  padding-bottom: 30px;
  font-weight: 600;
}
.service h2,
.service .h2 {
  font-weight: 600;
}
.service .step-row {
  padding-top: 70px;
}
.service .card .icon-round {
  height: 100px;
  width: 100px;
  background-color: #fff4ce;
}
.service .card .icon-round::before {
  position: absolute;
  content: '';
  top: 27px;
  left: -22px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-image: url(./../../assets/images/service/service-round-icon-layer.svg);
  background-repeat: no-repeat;
  z-index: 0;
  background-position: center;
  transform: rotate(349deg);
}
.service .card .icon-round i {
  font-size: 35px;
}
.service .card h5,
.service .card .h5 {
  color: #7a8496;
  padding-top: 30px;
}
.service .card h3,
.service .card .h3 {
  font-weight: 600;
  padding: 15px 0 15px;
}
.service .card p {
  color: #7a8496;
  line-height: 30px;
}

.our-service {
  padding: 150px 0;
  background-color: #f9f9fb;
}
.our-service::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-image: url(./../../assets/images/our-service/our-service-background.svg);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}
.our-service small,
.our-service .small {
  color: #7a8496;
  padding-bottom: 30px;
  font-weight: 600;
}
.our-service h2,
.our-service .h2 {
  font-weight: 600;
}
.our-service p {
  color: #7a8496;
  line-height: 30px;
  padding: 25px 0 35px;
}
.our-service ul li {
  padding-bottom: 25px;
  line-height: 30px;
}
.our-service ul li i {
  color: #37a05b;
  position: relative;
  top: 3px;
}
.our-service ul li span {
  width: 90%;
  font-weight: 600;
}

.portfolio {
  padding: 150px 0;
}
.portfolio::before {
  position: absolute;
  content: '';
  top: 80px;
  left: 0px;
  width: 100%;
  height: 92%;
  background-size: contain;
  background-image: url(./../../assets/images/portfolio/portfolio-backoverlay.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.portfolio .shape {
  top: -15px;
  right: -100px;
  height: 130px;
}
.portfolio small,
.portfolio .small {
  padding-bottom: 30px;
  font-weight: 600;
}
.portfolio h2,
.portfolio .h2 {
  font-weight: 600;
}
.portfolio .step-row {
  padding-top: 70px;
  padding-bottom: 80px;
}
.portfolio .card {
  border-radius: 30px;
}
.portfolio .card .icon {
  height: 80px;
  width: 80px;
  font-size: 25px;
  background-color: #2753c9;
}
.portfolio .card h3,
.portfolio .card .h3 {
  font-weight: 600;
  padding: 30px 0 20px;
}
.portfolio .card p {
  color: #92acf2;
  line-height: 30px;
}
.portfolio .portfolio-slider .owl-item .item {
  margin: 0 -20px 0 -50px;
}
.portfolio .portfolio-slider .owl-stage {
  left: 50px;
}
.portfolio .portfolio-slider .owl-stage-outer {
  overflow: inherit;
}
.portfolio .portfolio-slider .item img {
  border-radius: 15px;
}

.pricing {
  padding: 50px 0;
}
.pricing::before {
  position: absolute;
  content: '';
  top: -40px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-image: url(./../../assets/images/price-plans/price-plan-background-icons.svg);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}
.pricing small,
.pricing .small {
  color: #7a8496;
  padding-bottom: 30px;
  font-weight: 600;
}
.pricing h2,
.pricing .h2 {
  font-weight: 600;
}
.pricing .form-switch {
  margin: 20px 0 20px;
}
.pricing .form-switch label {
  font-weight: 600;
}
.pricing .form-switch .form-check-input {
  margin: 0px 15px;
  height: 22px;
  width: 45px;
}
.pricing .pricing-head::after {
  position: absolute;
  content: '';
  top: 50px;
  right: 0px;
  left: 0;
  width: 130px;
  height: 130px;
  background-size: contain;
  background-image: url(./../../assets/images/price-plans/dot-shape.png);
  background-repeat: no-repeat;
  z-index: -1;
  background-position: center;
  margin: 0 auto;
}
.pricing .save::after {
  position: absolute;
  content: '';
  top: -13px;
  right: -30px;
  width: 28px;
  height: 25px;
  background-size: contain;
  background-image: url(./../../assets/images/price-plans/price-arrow.svg);
  background-repeat: no-repeat;
  z-index: -1;
  background-position: center;
}
.pricing .price-plan {
  padding-top: 70px;
}
.pricing .price-plan .badge {
  border-radius: 0px 0px 8px 8px;
  left: 0;
  right: 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  top: -10px;
  padding: 10px 12px;
}
.pricing .price-plan .badge::before {
  position: absolute;
  content: '';
  top: -2px;
  left: -9px;
  width: 12px;
  height: 12px;
  background-size: contain;
  background-image: url(./../../assets/images/price-plans/left-shape.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.pricing .price-plan .badge::after {
  position: absolute;
  content: '';
  top: -2px;
  right: -9px;
  width: 12px;
  height: 12px;
  background-size: contain;
  background-image: url(./../../assets/images/price-plans/right-shape.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.pricing .price-plan .card-body::before {
  position: absolute;
  content: '';
  top: 5%;
  right: 0;
  width: 185px;
  height: 185px;
  background-size: contain;
  background-image: url(./../../assets/images/price-plans/price-overlay.svg);
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  text-align: center;
  margin: 0 auto;
}
.pricing .price-plan .card-body h2 sup,
.pricing .price-plan .card-body .h2 sup {
  color: #7a8496;
  top: 22px;
}
.pricing .price-plan .card-body p {
  color: #7a8496;
  line-height: 32px;
  padding: 0px 0 15px;
}
.pricing .price-plan .card-body ul li {
  padding-bottom: 18px;
  line-height: 30px;
}
.pricing .price-plan .card-body ul li i {
  color: #37a05b;
  position: relative;
  top: 3px;
}
.pricing .price-plan .card-body ul li span {
  width: 90%;
  font-weight: 600;
}

.faq {
  padding: 150px 0;
  background-color: #f9f9fb;
}
.faq::before {
  position: absolute;
  content: '';
  top: 150px;
  left: 0px;
  width: 100%;
  height: 90%;
  background-size: contain;
  background-image: url(./../../assets/images/faq/faq-backlayer-icons.svg);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}
.faq small,
.faq .small {
  color: #7a8496;
  padding-bottom: 30px;
  font-weight: 600;
}
.faq h2,
.faq .h2 {
  font-weight: 600;
}

.contact {
  padding: 180px 0 80px;
}
.contact::before {
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}
.contact .dots-shape-left {
  left: -75px;
  height: 110px;
  top: -80px;
  width: 110px;
}
.contact .dots-shape-right {
  right: -15px;
  height: 110px;
  width: 110px;
  top: -35px;
}
.contact small,
.contact .small {
  color: #7a8496;
  padding-bottom: 30px;
  font-weight: 600;
}
.contact h2,
.contact .h2 {
  font-weight: 600;
}
.contact .agree {
  color: #92acf2;
  line-height: 30px;
  padding-bottom: 25px;
}
.contact .testimonial .details {
  padding: 90px 150px 30px 0;
}
.contact .testimonial .details::before {
  position: absolute;
  content: '';
  top: 40px;
  left: 0px;
  width: 82%;
  height: 1px;
  background-color: #fff;
  z-index: 0;
  opacity: 0.1;
}
.contact .testimonial .details::after {
  position: absolute;
  content: '';
  top: 70px;
  left: 0px;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
}
.contact .testimonial .details p {
  line-height: 34px;
}
.contact .testimonial .details .avtar-img {
  background-color: #fec572;
  height: 55px;
  width: 55px;
}
.contact .trusted-companies {
  padding-top: 150px;
}
.contact .trusted-companies ul li {
  margin: 40px 25px 0;
  display: flex;
  justify-content: center;
}

.footer {
  padding: 170px 0 100px;
  background-color: #001c68;
}
.footer .footer-logo-col {
  padding-right: 120px;
}
.footer .footer-logo-col p {
  padding: 35px 0 30px;
  line-height: 30px;
}
.footer .footer-logo-col .callus {
  line-height: 30px;
}
.footer h5,
.footer .h5 {
  padding-bottom: 30px;
}
.footer ul li a {
  display: block;
  padding-bottom: 25px;
  text-decoration: none;
  color: #92acf2;
  font-weight: 500;
}
.footer ul li a:hover {
  color: #ee7b11;
}
.footer
  .subscribe
  .input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.footer
  .subscribe
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.footer .copyrights {
  padding-top: 70px;
}

.fw-500 {
  font-weight: 500;
}

body,
html {
  overflow-x: hidden;
}

.section-bg-icons::before {
  position: absolute;
  content: '';
  /* background-image: url(./../../assets/images/section-bg/team-feature-bg.svg); */
  background-repeat: no-repeat;
  height: 130%;
  width: 100%;
  background-size: contain;
  background-position: center;
  top: 0px;
  z-index: -1;
}

.blue-light {
  color: #92acf2 !important;
}

.br-15 {
  border-radius: 15px;
}

@media screen and (max-width: 9999px) and (min-width: 2400px) {
  .hero-banner::before {
    width: 55%;
  }
  .hero-banner::after {
    width: 45%;
  }
  .contact::before {
    background-size: cover;
  }
}
@media screen and (max-width: 2399px) and (min-width: 1700px) {
  .hero-banner::before {
    width: 60%;
  }
  .hero-banner::after {
    width: 40%;
  }
  .contact::before {
    background-size: cover;
  }
}
/*max-width-1024px*/
@media (max-width: 1199.98px) {
  .main-header .offcanvas .navbar-nav .nav-link {
    padding: 0 0 20px;
  }
  .hero-banner .left-hero-color {
    padding-top: 75px;
  }
  .hero-banner .left-hero-color h1,
  .hero-banner .left-hero-color .h1 {
    font-size: 60px;
  }
  .hero-banner .right-hero-color {
    padding-top: 100px;
  }
  .service,
  .our-service,
  .portfolio,
  .pricing,
  .faq {
    padding: 100px 0;
  }
  .portfolio .portfolio-wrap {
    position: unset;
  }
  .card-body {
    padding: 30px;
  }
  .price-plan .card-body {
    padding: 40px 15px 30px;
  }
  .footer {
    padding: 80px 0 80px;
  }
  .footer .footer-logo-col {
    padding-right: 0;
  }
  .footer .subscribe .input-group .form-control {
    width: 100%;
  }
  .footer .subscribe .input-group button {
    width: 100%;
    margin-top: 15px;
  }
}
/*max-width-991.98px*/
@media (max-width: 991.98px) {
  .hero-banner .left-hero-color h1,
  .hero-banner .left-hero-color .h1 {
    font-size: 40px;
  }
  .hero-banner .left-hero-color p {
    padding: 20px 0;
  }
  .service,
  .our-service,
  .portfolio,
  .pricing,
  .faq {
    padding: 80px 0;
  }
  .service .row .col-xxl-4:last-child {
    margin-top: 30px;
  }
  .price-plan .col-xxl-4:last-child {
    margin-top: 30px;
  }

  .contact .testimonial .details {
    padding: 90px 150px 30px 0;
  }
  .contact .trusted-companies {
    padding-top: 80px;
  }
  .footer .footer-logo-col p {
    padding: 25px 0 20px;
  }
  .footer .row .col-xxl-4 {
    margin-bottom: 30px;
  }
  .footer h5,
  .footer .h5 {
    padding-bottom: 20px;
  }
}
/*max-width-767.98px*/
@media (max-width: 767.98px) {
  .main-header {
    padding-top: 30px;
  }
  .service,
  .our-service,
  .portfolio,
  .pricing,
  .faq {
    padding: 40px 0;
  }
  .hero-banner {
    padding-bottom: 30px;
  }
  .hero-banner::after {
    width: 100%;
    height: 35%;
    top: 65%;
  }
  .hero-banner::before {
    width: 100%;
    height: 65%;
  }
  .hero-banner .left-hero-color {
    padding-top: 100px;
  }
  .hero-banner .left-hero-color h1,
  .hero-banner .left-hero-color .h1 {
    max-width: 100%;
    font-size: 32px;
  }
  .hero-banner .right-hero-color {
    padding-top: 20px;
  }
  br {
    display: none;
  }
  .fs-2 {
    font-size: 30px !important;
  }
  .service small,
  .service .small,
  .portfolio small,
  .portfolio .small {
    padding-bottom: 20px;
  }
  .service .step-row,
  .portfolio .step-row {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .service .row .col-xxl-4,
  .portfolio .row .col-xxl-4 {
    margin-bottom: 20px;
  }
  .service .row .col-xxl-4:last-child,
  .portfolio .row .col-xxl-4:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }
  .section-btn {
    margin-top: 20px;
  }
  .our-service img {
    margin-bottom: 20px;
  }
  .our-service small,
  .our-service .small {
    padding-bottom: 20px;
  }
  .our-service p {
    padding: 20px 0 25px;
  }
  .our-service ul li i {
    top: 5px;
  }
  .pricing .pricing-head::after {
    top: 20px;
    width: 100px;
    height: 100px;
  }
  .pricing .price-plan .col-xxl-4 {
    margin-bottom: 25px;
  }
  .pricing .price-plan .col-xxl-4:last-child {
    margin-top: 0;
  }
  .pricing .price-plan .card-body::before {
    top: 100px;
  }
  .pricing .price-plan .card-body ul li {
    padding-bottom: 5px;
  }
  .pricing .price-plan h2 .fs-2,
  .pricing .price-plan .h2 .fs-2 {
    font-size: 40px !important;
  }
  .faq .accordion-block .accordion-button {
    align-items: flex-start;
    padding: 20px;
  }
  .faq .accordion-block .accordion-button:not(.collapsed)::after {
    height: 15px;
    width: 15px;
    top: 10px;
  }
  .faq .accordion-block .accordion-body {
    padding: 20px;
  }
  .contact {
    padding: 40px 0 40px;
  }
  .contact .dots-shape-left {
    display: none;
  }
  .contact .agree {
    padding-bottom: 10px;
  }
  .contact small,
  .contact .small {
    padding-bottom: 20px;
  }
  .contact .testimonial {
    padding-bottom: 20px;
  }
  .contact .testimonial .details {
    padding: 30px 0px 30px 0;
  }
  .contact .testimonial .details::before {
    top: 20px;
    width: 100%;
  }
  .contact .trusted-companies {
    padding-top: 40px;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .footer {
    padding: 40px 0 40px;
  }
  .footer h5,
  .footer .h5 {
    padding-bottom: 15px;
  }
  .footer ul li a {
    padding-bottom: 15px;
  }
  .footer .row .col-xxl-2 {
    margin-bottom: 30px;
  }
  .footer .copyrights {
    padding-top: 10px;
    line-height: 30px;
  }
  .portfolio .portfolio-slider .owl-stage {
    left: 35px;
  }
}
/*max-width 576px*/
@media (max-width: 575.98px) {
  .card-action .btn {
    width: 100%;
  }
  .contact .contact-btn {
    width: 100% !important;
  }
} /*# sourceMappingURL=style.css.map */
