u {
  box-shadow: inset 0 -0.5em 0 0 #fedfd6;
  text-decoration: none;
}

.App {
  padding-bottom: 24px;
}

body {
  color: #333; /* Color de texto principal */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #444; /* Color de títulos */
}
p {
  color: #555; /* Color de texto secundario */
}
